
import React, { useState, useEffect, useLayoutEffect}  from 'react';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
import '../../App.css'; 
export default function Loading4(props) {
    const [textCode, setTextCode] = useState('');
    const [bgColor, setBgColor] = useState('');

    useLayoutEffect(() => {
     
        const school_bgcolor = localStorage.getItem('school_bgcolor');
        const text_code      = localStorage.getItem('text_code');
        setBgColor(school_bgcolor);
        setTextCode(text_code);
    
      },[]);



if(props.Switch == 1){
  return (
    <div className="dashboardLoder Backdropdashboard"   style={{backgroundColor:props.BgColor,}}>
       
        <center>
 
            <div style={{textAlign:'center',marginTop:'15%'}}>
            <img src="images/loading.gif"   style={{ width: 50, height: 50 }} /> 
            <p  style={{ textAlign: 'center',color:'#fff',fontSize:15 }}> {props.MessgNote}</p>

             
            </div>  

        </center>
    
  </div>

  );
}else{


  return (
    <div className="dashboardLoder Backdropdashboard"   style={{backgroundColor:props.BgColor,}}>
       
        <center>
 
            <div style={{textAlign:'center',marginTop:'15%'}}>
            <img src="images/loading.gif"   style={{ width: 50, height: 50 }} /> 
            <p  style={{ textAlign: 'center',color:'#fff',fontSize:15 }}> {props.MessgNote}</p>
       
            <Row>            
                <Col onClick={props.RefreshPage}> 
                <Button style={{backgroundColor:'#777777',marginLeft:10,color:'#fff',borderColor:'#777777'}}> Try Again</Button>
                </Col>
                     
                <Col  onClick={props.ReLoadHanduler}>
                <Button> Continue </Button>
                </Col>
            </Row>            
             


            </div>  

        </center>
    
  </div>

  );
}

}

 