import React from 'react'; 
import './Loading.css';

export default function Loading() {
  return (
    <div  className=""  style={{width:'100%',flex: 1,backgroundColor:'#fff'}}>
  
  
 

       <div className='Loader_bg loader_center'>  
     
      
        <p  style={{fontWeight: 'bold', fontSize: 35,color:'white', }}> App Loading ..</p>       
        <img src="images/loading.gif"   style={{ width: 50, height: 50 }} />
 
     </div> 

    </div>
 
  );
}


 