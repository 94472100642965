
import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BaseAction, imageURL } from '../../BaseUrl';
import { FaUserCheck,FaUserLock } from "react-icons/fa";
import { MdTipsAndUpdates } from "react-icons/md";
import { BsShieldLockFill } from "react-icons/bs";
import Modal from "../UI/Modal/Modal";  
import { BasePageAction } from '../../BaseUrl';
import md5               from 'md5-hash';
import { AuthContext } from '../../components/context';
import '../Login/Login.css';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row } from 'react-bootstrap';
import {FaArrowLeft,} from "react-icons/fa"; 

function PasswordReset() { 
  const { signOut, bgLogo } = React.useContext(AuthContext);
  const [logoutSet, setLogoutSet] = useState(false);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [password_2, setPassword_2] = useState('');
  const navigate = useNavigate();    
  const[loginData, setLoginData] = useState([]);  
  ////////////////////////////////////////////////////////////  
 
  const LoginOptionUpdate =() => { 
    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 
 
  };

  useLayoutEffect(() => {
 
  
    LoginOptionUpdate();
 
           
  },[]); 
   
 
  
  
 


  const showHandler =()=>{

      if(logoutSet == true){

      signOut();
      navigate('/');
      }
      setShow(false);
   
 }
 


  const handleSubmit = e =>  {
  e.preventDefault();

  setShow(true);
  
  if(password == '' || password_2 == '' 
  ){
    setMessage('All form fields are required');
  }
  else
  {

      const data = {   
        confirmPassword: md5(password_2), 
        password: md5(password),
        studentID:loginData.online_stu_id,
        school_code:loginData.school_code,
    
      };

     
      if(password_2 == password)
    {  
        var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        
        fetch(BasePageAction+"StudentPasswordReset",
        {
        method:'POST',
        header:headers,
        body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
         


              if(response[0].success == 'success')
              {
                setLogoutSet(true);
               setMessage(response[0].feedback);
                      
              
              }
              else   
              {
                
                setMessage(response[0].feedback);
              }
            


       
      
      })
      .catch(error => {
        setMessage('Oops! network error, please check network data and try again');
      });

    }
    else
    {
        setMessage('Oops!, your password did not match. Please try again');

    }

}
}
 
// <img src="images/favicon.png"   className="imgLogoL"/> <br />
 
    if(show){ 
      return (
        <div>
              {
                    
                message == ''?
                  <center>
                    <Modal show={show}  >    
                    <img src="images/loading.gif"  style={{height: '100px'}}   /> 
                    <br  /> <small style={{color: 'white'}}>Please Wait..</small>
                    </Modal> 
                  </center>
                  :

                <center>
                  
                  
                  <Modal clicked={showHandler} show={show}   > 
                    
                      <div  className="rackdropDiv" style={{color: 'white'}}  > 
                      <MdTipsAndUpdates className="blink_me" style={{fontSize: 60,color: '#0278c0'}} /><br/>
                        {message} 
                      </div>

                  </Modal>
                  

                </center>

            
              }

      </div>

      )
    }
    else
    {
       
        return (
        <div   style={{ padding: 20,}} >
           <div className="ContainerP"> 
 
            <div  style={{marginTop:100,fontSize: 18,color: 'black', }}> STUDENT <br /> PASSWORD UPDATE</div> 
       
                <form   onSubmit={handleSubmit}>



                <div className='form-inputs'>
                <label htmlFor='create-phone' className='form-label' style={{color: 'black'}}> <BsShieldLockFill style={{fontSize: 25,color: '#0278c0'}} />  Create New Password </label>
                <input
                style={{color: 'black',backgroundColor:'#fff'}}
                id="stuID" 
                name="stuID"
                type="password"
                className="form-input-login" 
                onChange={e=> setPassword_2(e.target.value)}
                />

                </div>


        


                <div className='form-inputs'>
                <label htmlFor='create-password' className='form-label' style={{color: 'black',marginTop:30}}>  <BsShieldLockFill style={{fontSize: 20,color: '#0278c0'}} />  Confirm New Password</label>
        

                <input
                style={{color: 'black',backgroundColor:'#fff'}}
                id="password"
                type="password"
                name="password"
                className="form-input-login"
                
                onChange={e=> setPassword(e.target.value)}
                />
                </div>




                <button type="submit"   className="btntab--active" style={{marginTop: '30px',width: '100%',   }}>
                UPDATE PASSWORD
                </button>

                <div style={{flexDirection: 'row',justifyContent: 'space-between',marginTop:3,marginBottom:8, fontSize: 14}}>
                    
            

            </div>
                </form>
        
           </div>
        </div>
        )


    }
    
  }
export default PasswordReset