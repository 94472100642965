import React, { useState, useEffect, useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'
import { GiWindpump } from "react-icons/gi";
import { MdOutlinePhoneIphone,MdOutlineMarkEmailRead } from "react-icons/md";
import {  FaChalkboardTeacher,FaDatabase,FaHourglassEnd,FaExternalLinkAlt,FaUser,FaEyeSlash ,FaFile } from "react-icons/fa";  
import { GrLogout } from "react-icons/gr";
import '../../index.css';
 import { AuthContext } from '../context'; 
import './Dashboard.css'; 
import '../../App.css'; 
import { BaseAction, imageURL } from '../../BaseUrl';  
import Loading4 from '../loaders/Loading4'; 
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
 



 

const Dashboard = (props) => {
  const [message, setMessage] = useState('');   
  const navigate = useNavigate();    

  const {  reloadUserData,signOut } = React.useContext(AuthContext);
 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  //////////////////////////////////////////////////////////////
  const [registeredSubjects, setRegisteredSubjects] = useState(false);  
  //////////////////////////////////////////////////////////////
  const[loginData, setLoginData] = useState([]);  
  //////////////////////////////////////////////////////////////
  const [studentOnList, setStudentOnList] = useState([]);  
  const [textCode, setTextCode] = useState('');
  const [bgColor, setBgColor] = useState('');


 
 
  const LoginOptionUpdate =() => {
     
 

      const value =  localStorage.getItem('login_session');
      const data = JSON.parse(value); 
      setLoginData(data); 

     
    };

    useEffect(() => {


      const school_bgcolor = localStorage.getItem('school_bgcolor');
      const text_code      = localStorage.getItem('text_code');
      setBgColor(school_bgcolor);
      setTextCode(text_code);

      LoginOptionUpdate();

       //  if(loginData.school_bgcolor == null){
      //   signOut();
      //  }else{
      //     LoginOptionUpdate();
      //  }
             
    },[]); 

 
 
   
 useLayoutEffect(() => {
    
  const stData  = localStorage.getItem('studentOnListData');  
  const data = JSON.parse(stData);
    if(data == null){

      setActivateLoader(true);
      setIsLoading2(true);
      FetchEnrolledStudents();

    }else{
 
      setStudentOnList(data);
      //console.log(data);
    }
  
  },[]);
        

  

  const FetchEnrolledStudents =() => {

    const parent_code =  localStorage.getItem('parentCode');
    const sch_code    =  localStorage.getItem('schoolCode');
    
     
    setActivateLoader(true);
    setIsLoading2(true);


    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"StudentOnList&parent="+parent_code+"&school_code="+sch_code,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  

                 
                    localStorage.setItem('studentOnListData',JSON.stringify(responseJson)); 
                    setStudentOnList(responseJson);
                    setActivateLoader(false);
                    setIsLoading2(false);

            })
            .catch((error) => { 

                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')

            });
  }
 
 
  const FetchStudentSubjects = (stu_id, schl_code, stu_class, stu_photo,stu_name) =>{

   alert(stu_id+schl_code+stu_class+stu_photo+stu_name)

  }

  
  
  const  SubEndPitcherDown =() =>{
  /// double button modal OFF
  setRegisteredSubjects(false);
  }   


  const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }

if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Student"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{

          return (
        
            <div  style={{textAlign:"center",padding: 20,}} >

          <div     style={{backgroundColor:bgColor,color:textCode,width:'100%',paddingTop:20,paddingBottom:10}}> 
            <Container>  
            <Row>
                  <Col xs={3}>
                  <img src={imageURL+loginData.school_code+'/'+loginData.school_logo} style={{marginTop:10,  }} className="imgLogo"/>
                  </Col>

                  <Col xs={9} style={{textAlign:'left'}}> 
                      <p  className="Bannerheader"style={{fontWeight: 'bold',  color:textCode,textTransform:'uppercase'}}> {props.schoolName}</p>    
                      <p  className="Bannerheader2"style={{color:textCode, marginTop:-15, }}> 
                      Address: {props.schoolAddress} <br/>
                      Phone:   {props.schoolPhone} <br/>
                      Email:   {props.schoolEmail}<br/>
                      Mottor:  {props.schoolMotor}
                      </p> 
                  </Col>
              </Row>
            </Container>

          </div>
        

         
                       
                        <Card  className="BoxShaddow"   style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                          <Row>       
                          <div className="colTextTwo" style={{ color:'black', marginTop:20}}> <FaFile   style={{paddingTop:2}} size={25}  color={'black'} /> STUDENT PROFILE </div>
                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:20}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaUser   style={{paddingTop:2}} size={20}  color={'black'} /> {loginData.student_name}</p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Fullname </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <MdOutlinePhoneIphone   style={{paddingTop:2}} size={20}  color={'black'} /> {loginData.online_stu_id}</p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Online Code/ID </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <MdOutlinePhoneIphone   style={{paddingTop:2}} size={20}  color={'black'} /> {loginData.schl_stu_no}</p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>School Code/ID </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaDatabase   style={{paddingTop:2}} size={20}  color={'black'} />{loginData.student_class}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Class </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaDatabase   style={{paddingTop:2}} size={20}  color={'black'} />{loginData.sub_status}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>CBT Payment Status</div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaDatabase   style={{paddingTop:2}} size={20}  color={'black'} />{loginData.student_teacher}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Class Teacher</div>      
                                </Col>

                              
                 
 
                            </Row>
                        </Card> 





                        <Card  className="BoxShaddow"   style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                          <Row>       
                          <div className="colTextTwo" style={{ color:'black', marginTop:20}}> <FaFile   style={{paddingTop:2}} size={25}  color={'black'} />   PARENT PROFILE </div>
                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:20}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaUser   style={{paddingTop:2}} size={20}  color={'black'} /> {loginData.guidance_name}</p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Fullname </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <MdOutlinePhoneIphone   style={{paddingTop:2}} size={20}  color={'black'} /> {loginData.username}</p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Phone  number </div>      
                                </Col>


                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}> <FaDatabase   style={{paddingTop:2}} size={20}  color={'black'} />{loginData.parent_code}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Parent Code/ID </div>      
                                </Col>

                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}>  <FaChalkboardTeacher    style={{paddingTop:2}} size={20}  color={'black'} />{loginData.school_code}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>School code/ID </div>      
                                </Col>
                 

                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}>  <GiWindpump     style={{paddingTop:2}} size={20}  color={'black'} />{loginData.address}  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Address </div>      
                                </Col>
                 
                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}>
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}>  <MdOutlineMarkEmailRead     style={{paddingTop:2}} size={20}  color={'black'} />  {loginData.email} </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>Email </div>      
                                </Col>
                 
                            </Row>
                        </Card> 




                        <Card  className="BoxShaddow"   style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                        <div className="colTextTwo" style={{ color:'black', marginTop:20}}> <FaFile   style={{paddingTop:2}} size={25}  color={'black'} /> PAWSSWORD SETTINGS </div>
                          <Row>       
                           
  
                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}  onClick={() => navigate('/settings/password-reset')} >
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}>  <FaEyeSlash      style={{paddingTop:2}} size={20}  color={'black'} /> **********  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>  Change Login Password  </div>      
                                </Col>
                 
                            </Row>
                        </Card> 


                        <Card  className="BoxShaddow"   style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                         <Row>       
                           
  
                                <Col xs={9} style={{textAlign:'left',paddingLeft:30,marginTop:30}}  onClick={() => navigate('/settings/password-reset')} >
                                <p className="colTextThree" style={{fontWeight:'bold,', color:'black',marginBottom: 5, textTransform:'uppercase'}}>  <GrLogout      style={{paddingTop:2}} size={20}  color={'black'} />  </p>
                                <div  className="colTextFour" style={{ color:'black', marginBottom: 2, textAlign:'left',paddingLeft:30}}>  Log Out  </div>      
                                </Col>
                 
                            </Row>
                        </Card> 

                    
        

           

            </div>
            );
                
 

        } 
};

export default Dashboard; 
 

  