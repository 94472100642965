import React, { useState, useEffect, useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'
import { MdTipsAndUpdates,MdVerified,MdCancelPresentation } from "react-icons/md";
// import { BsShieldFillCheck } from "react-icons/bs";
// import { GiMoneyStack } from "react-icons/gi";
// import { GiAlliedStar} from "react-icons/gi";    
//  import { MdOutlineGppGood, MdGppGood,MdGroups} from "react-icons/md";  
// import {  FaHourglassHalf,FaHourglassEnd,FaExternalLinkAlt,FaUsers,FaUserTimes } from "react-icons/fa";  
// import { AiOutlineReload} from "react-icons/ai"; 
import {GiPadlock,GiPadlockOpen,GiCheckMark} from "react-icons/gi";  
import Modal from "../UI/Modal/Modal";  
//  
 import { AuthContext } from '../../components/context'; 
import './Dashboard.css'; 
import '../../App.css'; 
import { ResultDownload, imageURL } from '../../BaseUrl'; 
import Loading3 from '../loaders/Loading3'; 
import Loading4 from '../loaders/Loading4';
import Loading from '../loaders/Loading';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
 



 

const Dashboard = (props) => {
  const [message, setMessage] = useState('');   
  const navigate = useNavigate();    

  const {   signOut } = React.useContext(AuthContext);
 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  //////////////////////////////////////////////////////////////
  const [registeredSubjects, setRegisteredSubjects] = useState(false);  
  //////////////////////////////////////////////////////////////
  const[loginData, setLoginData] = useState([]);  
  //////////////////////////////////////////////////////////////
  const [studentOnList, setStudentOnList] = useState([]);  
  const [textCode, setTextCode] = useState('');
  const [bgColor, setBgColor] = useState('');


  useEffect(() => {
        
  
  
  
    setTimeout(async() => {
    
          LoginOptionUpdate();  
          
          if(loginData.portal_lock == 'close'){
            signOut();
           }


    }, 10000);
    
    
                      
    });
 
  const LoginOptionUpdate =() => {
     
 

      const value =  localStorage.getItem('login_session');
      const data = JSON.parse(value); 
      setLoginData(data); 

     
    };

    useEffect(() => {


      const school_bgcolor = localStorage.getItem('school_bgcolor');
      const text_code      = localStorage.getItem('text_code');
      setBgColor(school_bgcolor);
      setTextCode(text_code);

      LoginOptionUpdate();

 
             
    },[]); 

 
 
   
 useLayoutEffect(() => {
    
  const stData  = localStorage.getItem('studentOnListData');  
  const data = JSON.parse(stData);
 
      setStudentOnList(data);
  

  },[]);
        

 

  const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }

if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Student"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{

          return (
        
            <div  style={{textAlign:"center",padding: 20,}} >

          <div     style={{backgroundColor:bgColor,color:textCode,width:'100%',paddingTop:20,paddingBottom:10}}> 
            <Container>  
            <Row>
                  <Col xs={3}>
                  <img src={imageURL+loginData.school_code+'/'+props.schoolLogo} style={{marginTop:10,  }} className="imgLogo"/>
                  </Col>

                  <Col xs={9} style={{textAlign:'left'}}> 
                      <p  className="Bannerheader"style={{fontWeight: 'bold',  color:textCode,textTransform:'uppercase'}}> {props.schoolName}</p>    
                      <p  className="Bannerheader2"style={{color:textCode, marginTop:-15, }}> 
                      Address: {props.schoolAddress} <br/>
                      Phone:   {props.schoolPhone} <br/>
                      Email:   {props.schoolEmail}<br/>
                      Mottor:  {props.schoolMotor}
                      </p> 
                  </Col>
              </Row>
            </Container>

          </div>
          <div style={{fontSize:20,color:'black',fontWeight: 'bold',marginTop:20}}>Hi, {loginData.student_name} </div>

           
                        <Card  className="BoxShaddow" style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                          <Row>            
                              <Col  xs={3}>
                              <div style={{marginTop:30}}>
                                <p style={{fontWeight: 400,color:loginData.school_bgcolor,marginBottom: 5,fontSize:15,textAlign:'center',textTransform:'capitalize' }}> </p> 
                                <img src={imageURL+loginData.school_code+'/'+loginData.photo} style={{marginBottom:20,width: 100,height: 100,borderRadius:800}} /><br/>
                                <div   onClick={() => navigate('./student-exam-result?online_stu_id='+loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)} style={{backgroundColor:bgColor,color:textCode,fontSize:11,borderRadius:2,padding:5,marginLeft:5}}>Check Score </div> 
                               
                               
                                <a   href={ResultDownload+'school_payment_link.php?school_code='+loginData.school_code+''}>
                                 <Button style={{backgroundColor:'#c7d2f8',color:'black',fontSize:9,marginBottom: 2,borderColor:textCode,width:'100%',marginTop:5,marginLeft:5}}  > Online Payment  </Button> 
                               </a>


                                <a   href={ResultDownload+'student_clearance.php?student_id='+loginData.online_stu_id+'&token='+loginData.token+'&parent_id='+loginData.parent_code+'&school_code='+loginData.school_code}>
                                 <Button style={{backgroundColor:'#c7d2f8',color:'black',fontSize:9,marginBottom: 2,borderColor:textCode,width:'100%',marginTop:5,marginLeft:5}}  > Student clearance </Button> 
                               </a>
                              </div>

                              </Col>

                  {loginData.sub_status == 'active'?
                  <Col xs={9} style={{textAlign:'left',paddingLeft:30,paddingTop:15}}> 
                  <Button  style={{backgroundColor:'#f2f2f2',color:'black',fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Class: {loginData.student_class} </Button>      
                  <Button  style={{backgroundColor:'#f2f2f2',color:'black',fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Student ID : {loginData.online_stu_id } </Button>     
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}  onClick={() => navigate('./stu-subject?online_stu_id='+   loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)} >Registered Subjects</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./scheme-of-work?online_stu_id='+loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Scheme Of Work</Button> 
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./general-cbt?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take CBT Training</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./weekly-assessment?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Weekly Assesment</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./mid-term-test?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take Mid-Term Test</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./school-exam?online_stu_id='+loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take School CBT Exam</Button>   
         
                  
                  </Col>
                  :
                  <Col xs={9} style={{textAlign:'left',paddingLeft:30}}>
                  <p style={{fontWeight: 'bold',color:'black',marginBottom: 5,fontSize:16,textTransform:'uppercase'}}>{loginData.student_name}</p>
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Class: {loginData.student_class} </Button>      
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Student ID : {loginData.online_stu_id } </Button>     
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>View Registered Subjects</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./general-cbt?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take CBT Training</Button>   
              
                  </Col>           


                  }



                            </Row>
                        </Card> 

        
           

            </div>
            );
                
 

        } 
};

export default Dashboard; 
 

  