import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BaseAction, imageURL } from '../../../BaseUrl'; 
import Loading4 from '../../loaders/Loading4';
import { AuthContext } from '../../../components/context'; 
import {resultInitialState} from './Constant'; 
import './index.css';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row ,Modal} from 'react-bootstrap';
import {FaArrowLeft,} from "react-icons/fa";  






function ResultChecker({StudenResultData,ResultType,ReLoadHanduler }) { 
 const [currentQuestion, setCurrentQuestion] = useState(0); 
 const[loginData, setLoginData] = useState([]); 
 const{student_results, student_subjects } = StudenResultData[currentQuestion]; 
 //////////////////////////////////////////////////////////
 const[schoolCode, setSchoolCode] = useState('');  
 const[studentID, setStudentID] = useState('');  
 const[studentClass, setStudentClass] = useState('');  
 const[studentName, setStudentName] = useState('');  
 const[studentPhoto, setStudentPhoto] = useState('');  
 const[studentTeacher, setStudentTeacher] = useState(''); 
 ////////////////////////////////////////////////////////////  
 const navigate = useNavigate(); 

  
 
 const search = useLocation().search;


 

  const LoginOptionUpdate =() => { 
    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 
 
  };

 
  useLayoutEffect(() => {
 

    const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
    const student_teacher= new URLSearchParams(search).get('student_teacher'); 
    const school_code    = new URLSearchParams(search).get('school_code'); 
    const student_class  = new URLSearchParams(search).get('student_class'); 
    const student_name   = new URLSearchParams(search).get('student_name'); 
    const photo          = new URLSearchParams(search).get('photo'); 
    
    setStudentTeacher(student_teacher);
    setSchoolCode(school_code);
    setStudentID(online_stu_id);
    setStudentClass(student_class);
    setStudentName(student_name);
    setStudentPhoto(photo);


   

    LoginOptionUpdate();
 
           
  },[]); 

 
     
        return (  
    <div  style={{textAlign:"center",padding: 20,}} >


            <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code}}> 
            <Container>  
            <Row>
                  <Col xs={5}>
                  <img src={imageURL+loginData.school_code+'/'+studentPhoto} style={{marginTop:10,  }} className="imgLogo2"/>
                  </Col>
    
                  <Col xs={7} style={{textAlign:'left'}}> 
                  <p  className="Bannerheader" style={{color:loginData.text_code,textTransform:'uppercase'}}> {studentName}</p>    
                  <p   className="Bannerheader2" style={{marginTop:-15,  textTransform:'uppercase'}}> 
                      Student Teacher: {studentTeacher} <br/>
                      Student ID:      {studentID} <br/>
                      Student Class:   {studentClass}<br/>
                      School Code:     {schoolCode}
                      </p> 
                  </Col>
              </Row>
            </Container>
            </div>         
            
            

            <div  style={{textAlign:'center',paddingBottom:200,marginLeft:10}}>


                        <div style={{color:'black',fontSize:20,textTransform:'uppercase',textDecorationLine: "underline",marginTop:50}}>
                        <span onClick={() => ReLoadHanduler('false')}> <FaArrowLeft   size={25} style={{color: loginData.school_bgcolor,marginRight:20}}/> </span>
                        {ResultType}  
                        </div>
               
 
                        
                        <Row style={{textAlign:'left'}}>  
                            <Col  >
                            <div style={{width:'100%',}}> 
                            
                                    {  
                                        student_subjects.map((answer, index) => ( 
                                            answer !== '' ? <li style={{fontSize:18,height:70,color:'black'}}> {answer} </li>: null 
                                        ))
                                    }
                      

                             </div> 
                         </Col>

                           <Col  xs={3}> 
                                <div style={{width:'90%',paddingRight:'10%',}}> 
                                            {  
                                                student_results.map((answer, index) => ( 
                                                    answer !== '' ? <li style={{fontSize:20,height:70,color:'black'}}>{answer}<br/> <span style={{fontSize:11}}>Marks</span>   </li>: null  
                                                )) 
                                            }
                                </div> 
                          </Col>

                        </Row>
                        
                        
                 
                

            </div>


     </div>
        );
            
        
      
}
 
export default ResultChecker

 