 
import './App.css';
import React, { useState, useEffect } from "react";
import Home from './components/Home/Home';  
import {  BrowserRouter,  Routes,   Route, } from "react-router-dom";








function App() {

 
  return (
  
      <Home   /> 
    
       
    
  );
 


}

export default App;

 
//https://react-bootstrap.github.io/getting-started/introduction/
//https://v5.reactrouter.com/web/api/Redirect
//https://www.npmjs.com/package/react-csv
//https://www.cluemediator.com/export-data-to-csv-in-react
//https://www.npmjs.com/package/react-export-excel
//https://npm.io/search/keyword:react-dataset
