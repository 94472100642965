import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BodyColor,TopTabColor }  from '../../BaseUrl';
import {FaArrowLeft,FaHourglassHalf,FaCoins,FaGamepad,FaUsers,FaEnvelopeOpenText,FaRegMoneyBillAlt,FaUnlock,FaLock,FaLockOpen,FaRegTrashAlt,FaHistory} from "react-icons/fa";  
import { BsCheck2Circle } from "react-icons/bs";
import { AuthContext } from '../context'; 
import {AiFillCloseCircle} from "react-icons/ai";  
import '../../App.css'; 
import { BaseAction, imageURL } from '../../BaseUrl'; 
import ExamAccessCode from '../loaders/ExamAccessCode'; 
import Loading4 from '../loaders/Loading4';
import Loading from '../loaders/Loading';
import GeneralCBTPage from './CBT/GeneralCBTPage';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row ,Modal} from 'react-bootstrap';



export default function  Examination() {
    const{  startCBT,endCBT } = React.useContext(AuthContext);
    const navigate = useNavigate();    
    const[activateLoader, setActivateLoader]   = useState(false); 
    const[isLoading2, setIsLoading2]   = useState(false);  
    const[accessCode, setAccessCode]   = useState(true);  
    const[cbtSwitchPage, setCbtSwitchPage]   = useState(false); 
    const[loginData, setLoginData] = useState([]);  
    const[message, setMessage] = useState('');  
    const[questions, setQuestions] = useState([]);
    const[studentSubjectList, setStudentSubjectList] = useState([]);  
    const[schoolCode, setSchoolCode] = useState('');  
    const[studentID, setStudentID] = useState('');  
    const[studentClass, setStudentClass] = useState('');  
    const[studentName, setStudentName] = useState('');  
    const[studentPhoto, setStudentPhoto] = useState('');  
    const[studentTeacher, setStudentTeacher] = useState(''); 
    const[messageSwitch, setMessageSwitch] = useState(false);
    const[subjecdID, setSubjecdID] = useState('');
    const[currentTime, setCurrentTime] = useState();
 
 const search = useLocation().search;

 const LoginOptionUpdate =() => {
     
 

    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 

   
  };


  useLayoutEffect(() => {
 

    LoginOptionUpdate();
 
           
  },[]); 



 
  const FetchAllStudentSubject =(online_stu_id,schoolCode) => {
 
    setActivateLoader(true);
    setIsLoading2(true);
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"registeredSubjects&online_stu_id="+online_stu_id+"&school_code="+schoolCode,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  
                
                 
                    localStorage.setItem('studentSubjectData',JSON.stringify(responseJson)); 
                    localStorage.setItem('onlineStuIdData',online_stu_id); 
                    setStudentSubjectList(responseJson);
                    setActivateLoader(false);
                    setIsLoading2(false);
  
            })
            .catch((error) => { 
  
                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')
  
            });
   
     
    };
  
  
  
      const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
      const student_teacher= new URLSearchParams(search).get('student_teacher'); 
      const school_code    = new URLSearchParams(search).get('school_code'); 
      const student_class  = new URLSearchParams(search).get('student_class'); 
      const student_name   = new URLSearchParams(search).get('student_name'); 
      const photo          = new URLSearchParams(search).get('photo'); 
    useLayoutEffect(() => {
      
  

      
      setStudentTeacher(student_teacher);
      setSchoolCode(school_code);
      setStudentID(online_stu_id);
      setStudentClass(student_class);
      setStudentName(student_name);
      setStudentPhoto(photo);
  
  
  
  
      const DataOnlineStuId  = localStorage.getItem('onlineStuIdData'); 
   
      const stData  = localStorage.getItem('studentSubjectData');  
      const data = JSON.parse(stData);
  
  
      if(data == null || DataOnlineStuId !== online_stu_id ){

          FetchAllStudentSubject(online_stu_id,school_code);
    
        }else{
     
          setStudentSubjectList(data);
          
        }

         

      },[]);
  
      useEffect(() => {

        if(online_stu_id == '' || student_teacher == '' || school_code == '' || student_class == '' ||student_name == '' || photo == ''){
        navigate('/');
        }

    })

const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    setCbtSwitchPage(false)
    
  }   
    
 const refreshPage = () => {
    
  //endCBT()
  navigate('/')    
  }
  
  
  const FetchGeneralCBTQuestion =(val) => {
    setQuestions('');
    setActivateLoader(true);
    setIsLoading2(true);

     

    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"SchoolExamQuestion&student_class="+studentClass+"&school_code="+schoolCode+"&cbt_subject="+val+"&student_code="+studentID+"&cbt_type=Examination",
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  
                   const dataQ = responseJson.dataQuestion;
                   dataQ.forEach((queItem) =>{
                    
                   
                    if(queItem.result == 'null')
                      {
                          
                            setQuestions(''); 
                            setCbtSwitchPage(false);
                            setMessage(queItem.question);
                            setMessageSwitch(true);

                        }else{
                            localStorage.setItem('studentCurrentExam',JSON.stringify(responseJson));   
                            setQuestions(responseJson);   
                            setAccessCode(true);                 
                        }


              })
                    setActivateLoader(false);
                    setIsLoading2(false);
  
            })
            .catch((error) => { 
  
                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')
  
            });
   
     
    };

    const CbtOpenHandler = (val,val2) => {
 
 
      setSubjecdID(val2)
      const subjectData  = localStorage.getItem('subject');  
      const stData  = localStorage.getItem('studentCurrentExam');  
      const data = JSON.parse(stData);
    
          if(val === subjectData  ) {
            localStorage.setItem('subject',val);
          }else{
            localStorage.setItem('subject',val);
            setQuestions(''); 
            FetchGeneralCBTQuestion(val2);
          }
    
    
            if(data !== null)
            { 
              setQuestions(data);  
    
    
    
    
    
            }else{
              setQuestions('');  
              FetchGeneralCBTQuestion(val2);
            }
        
    
            if(questions !== null){
              startCBT();
            setCbtSwitchPage(true);
    
            const value =  localStorage.getItem('test_time');
            if(value == null || value == ''){
              localStorage.setItem('test_time', loginData.test_time);
              setCurrentTime(Number(loginData.test_time));
        
              
            }else{
              setCurrentTime(value);
              
            }
    
    
    
    
            }else{
              setCbtSwitchPage(false);
            }
            
     
    
      }
    
 

if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Questions"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{
 
  if(cbtSwitchPage)
  {
    if(accessCode)
      {

            return(
              <div> 
              <ExamAccessCode 
              accessCode={setAccessCode}  
              token={questions.token}
              cbtSwitchPage={setCbtSwitchPage} 
              BgColor={loginData.school_bgcolor} 
              textCode={loginData.text_code} 
              MessgNote={"EXAMINATION ACCESS CODE "} 
              CBT={'Examination'}/>
              
              </div>
            )
            
      }           
      else  
      {   
            return(
                
              <GeneralCBTPage  
              CBTPage={'Examination'} 
              SubjecdID={subjecdID} 
              questions={questions.dataQuestion} 
              school_code={schoolCode}   
              studenCode={studentID} 
              student_class={studentClass} 
              score={loginData.exam_score}   
              time={currentTime}   
              FetchGeneralCBTQuestion={FetchGeneralCBTQuestion}
              />
            );
      
      
      }
  }
  else
  {

    return (
        
        <div  style={{textAlign:"center",padding: 20,}} >

        <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code}}> 
        <Container>  
        <Row>
              <Col xs={5}>
              <img src={imageURL+loginData.school_code+'/'+studentPhoto} style={{marginTop:10,  }} className="imgLogo2"/>
              </Col>

              <Col xs={7} style={{textAlign:'left'}}> 
              <p  className="Bannerheader" style={{color:loginData.text_code,textTransform:'uppercase'}}> {studentName}</p>    
              <p   className="Bannerheader2" style={{marginTop:-15,  textTransform:'uppercase'}}> 
                  Student Teacher: {studentTeacher} <br/>
                  Student ID:      {studentID} <br/>
                  Student Class:   {studentClass}<br/>
                  School Code:     {schoolCode}
                  </p> 
              </Col>
          </Row>
        </Container>

      </div>
      <div style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}>
      <span onClick={() => refreshPage()}> <FaArrowLeft   size={25} style={{color:  'black',marginRight:20}}/> </span>
      SCHOOL EXAMINATION
      </div>

      {
        studentSubjectList !== null  || studentSubjectList !== ''?
                  studentSubjectList.map((item) => (
                  <div  key={item.id} >
                    <Card  style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                      <Row>            
                          <Col  xs={12}>
                          <div style={{marginTop:30}}>
                         
                          {item.sub_1 != ''?  <p onClick={() => CbtOpenHandler(item.sub_1,'sub_1')}  className='TextHeader3 '> {item.sub_1} </p> :null }
                          {item.sub_2 != ''?  <p onClick={() => CbtOpenHandler(item.sub_2,'sub_2')}  className='TextHeader3 '> {item.sub_2} </p> :null }
                          {item.sub_3 != ''?  <p onClick={() => CbtOpenHandler(item.sub_3,'sub_3')}  className='TextHeader3 '> {item.sub_3} </p> :null } 
                          {item.sub_4 != ''?  <p onClick={() => CbtOpenHandler(item.sub_4,'sub_4')}  className='TextHeader3 '> {item.sub_4} </p> :null }
                          {item.sub_5 != ''?  <p onClick={() => CbtOpenHandler(item.sub_5,'sub_5')}  className='TextHeader3 '> {item.sub_5} </p> :null }
                          {item.sub_6 != ''?  <p onClick={() => CbtOpenHandler(item.sub_6,'sub_6')}  className='TextHeader3 '> {item.sub_6} </p> :null }
                          {item.sub_7 != ''?  <p onClick={() => CbtOpenHandler(item.sub_7,'sub_7')}  className='TextHeader3 '> {item.sub_7} </p> :null }
                          {item.sub_8 != ''?  <p onClick={() => CbtOpenHandler(item.sub_8,'sub_8')}  className='TextHeader3 '> {item.sub_8} </p> :null }
                          {item.sub_9 != ''?  <p onClick={() => CbtOpenHandler(item.sub_9,'sub_9')}  className='TextHeader3 '> {item.sub_9} </p> :null } 
                          {item.sub_10 != ''? <p onClick={() => CbtOpenHandler(item.sub_10,'sub_10')} className='TextHeader3 '> {item.sub_10}</p> :null }  
                          {item.sub_11 != ''? <p onClick={() => CbtOpenHandler(item.sub_11,'sub_11')} className='TextHeader3 '> {item.sub_11}</p> :null }  
                          {item.sub_12 != ''? <p onClick={() => CbtOpenHandler(item.sub_12,'sub_12')} className='TextHeader3 '> {item.sub_12}</p> :null }  
                          {item.sub_13 != ''? <p onClick={() => CbtOpenHandler(item.sub_13,'sub_13')} className='TextHeader3 '> {item.sub_13}</p> :null }  
                          {item.sub_14 != ''? <p onClick={() => CbtOpenHandler(item.sub_14,'sub_14')} className='TextHeader3 '> {item.sub_14}</p> :null }  
                          {item.sub_15 != ''? <p onClick={() => CbtOpenHandler(item.sub_15,'sub_15')} className='TextHeader3 '> {item.sub_15}</p> :null }  
                          {item.sub_16 != ''? <p onClick={() => CbtOpenHandler(item.sub_16,'sub_16')} className='TextHeader3 '> {item.sub_16}</p> :null }  
                          {item.sub_17 != ''? <p onClick={() => CbtOpenHandler(item.sub_17,'sub_17')} className='TextHeader3 '> {item.sub_17}</p> :null }  
                          {item.sub_18 != ''? <p onClick={() => CbtOpenHandler(item.sub_18,'sub_18')} className='TextHeader3 '> {item.sub_18}</p> :null }  
                          {item.sub_19 != ''? <p onClick={() => CbtOpenHandler(item.sub_19,'sub_19')} className='TextHeader3 '> {item.sub_19}</p> :null }  
                          {item.sub_20 != ''? <p onClick={() => CbtOpenHandler(item.sub_20,'sub_20')} className='TextHeader3 '> {item.sub_20}</p> :null }  
                          {item.sub_21 != ''? <p onClick={() => CbtOpenHandler(item.sub_21,'sub_21')} className='TextHeader3 '> {item.sub_21}</p> :null }  
                          {item.sub_22 != ''? <p onClick={() => CbtOpenHandler(item.sub_22,'sub_22')} className='TextHeader3 '> {item.sub_22}</p> :null }  
                          {item.sub_23 != ''? <p onClick={() => CbtOpenHandler(item.sub_23,'sub_23')} className='TextHeader3 '> {item.sub_23}</p> :null }  
                          {item.sub_24 != ''? <p onClick={() => CbtOpenHandler(item.sub_24,'sub_24')} className='TextHeader3 '> {item.sub_24}</p> :null }  
                          {item.sub_25 != ''? <p onClick={() => CbtOpenHandler(item.sub_25,'sub_25')} className='TextHeader3 '> {item.sub_25}</p> :null }  
                          {item.sub_26 != ''? <p onClick={() => CbtOpenHandler(item.sub_26,'sub_26')} className='TextHeader3 '> {item.sub_26}</p> :null }  
                          {item.sub_27 != ''? <p onClick={() => CbtOpenHandler(item.sub_27,'sub_27')} className='TextHeader3 '> {item.sub_27}</p> :null }  
                          {item.sub_28 != ''? <p onClick={() => CbtOpenHandler(item.sub_28,'sub_28')} className='TextHeader3 '> {item.sub_28}</p> :null }  
                          {item.sub_29 != ''? <p onClick={() => CbtOpenHandler(item.sub_29,'sub_29')} className='TextHeader3 '> {item.sub_29}</p> :null }  
                          {item.sub_30 != ''? <p onClick={() => CbtOpenHandler(item.sub_30,'sub_30')} className='TextHeader3 '> {item.sub_30}</p> :null }  
                          {item.sub_31 != ''? <p onClick={() => CbtOpenHandler(item.sub_31,'sub_31')} className='TextHeader3 '> {item.sub_31}</p> :null }  
                          {item.sub_32 != ''? <p onClick={() => CbtOpenHandler(item.sub_32,'sub_32')} className='TextHeader3 '> {item.sub_32}</p> :null }  
                          {item.sub_33 != ''? <p onClick={() => CbtOpenHandler(item.sub_33,'sub_33')} className='TextHeader3 '> {item.sub_33}</p> :null }  
                          {item.sub_34 != ''? <p onClick={() => CbtOpenHandler(item.sub_34,'sub_34')} className='TextHeader3 '> {item.sub_34}</p> :null }  
                          {item.sub_35 != ''? <p onClick={() => CbtOpenHandler(item.sub_35,'sub_35')} className='TextHeader3 '> {item.sub_35}</p> :null }  
                          {item.sub_36 != ''? <p onClick={() => CbtOpenHandler(item.sub_36,'sub_36')} className='TextHeader3 '> {item.sub_36}</p> :null }  
                          {item.sub_37 != ''? <p onClick={() => CbtOpenHandler(item.sub_37,'sub_37')} className='TextHeader3 '> {item.sub_37}</p> :null }  
                          {item.sub_38 != ''? <p onClick={() => CbtOpenHandler(item.sub_38,'sub_38')} className='TextHeader3 '> {item.sub_38}</p> :null }  
                          {item.sub_39 != ''? <p onClick={() => CbtOpenHandler(item.sub_39,'sub_39')} className='TextHeader3 '> {item.sub_39}</p> :null }  
                          {item.sub_40 != ''? <p onClick={() => CbtOpenHandler(item.sub_40,'sub_40')} className='TextHeader3 '> {item.sub_40}</p> :null }  
                          {item.sub_41 != ''? <p onClick={() => CbtOpenHandler(item.sub_41,'sub_41')} className='TextHeader3 '> {item.sub_41}</p> :null }  
                          {item.sub_42 != ''? <p onClick={() => CbtOpenHandler(item.sub_42,'sub_42')} className='TextHeader3 '> {item.sub_42}</p> :null }  
                          {item.sub_43 != ''? <p onClick={() => CbtOpenHandler(item.sub_43,'sub_43')} className='TextHeader3 '> {item.sub_43}</p> :null }  
                          {item.sub_40 != ''? <p onClick={() => CbtOpenHandler(item.sub_40,'sub_40')} className='TextHeader3 '> {item.sub_40}</p> :null }  
                          {item.sub_44 != ''? <p onClick={() => CbtOpenHandler(item.sub_44,'sub_44')} className='TextHeader3 '> {item.sub_44}</p> :null }  
                          {item.sub_45 != ''? <p onClick={() => CbtOpenHandler(item.sub_45,'sub_45')} className='TextHeader3 '> {item.sub_45}</p> :null }  
                          {item.sub_46 != ''? <p onClick={() => CbtOpenHandler(item.sub_46,'sub_46')} className='TextHeader3 '> {item.sub_46}</p> :null }  
                          {item.sub_47 != ''? <p onClick={() => CbtOpenHandler(item.sub_47,'sub_47')} className='TextHeader3 '> {item.sub_47}</p> :null }  
                          {item.sub_48 != ''? <p onClick={() => CbtOpenHandler(item.sub_48,'sub_48')} className='TextHeader3 '> {item.sub_48}</p> :null }  
                          {item.sub_49 != ''? <p onClick={() => CbtOpenHandler(item.sub_49,'sub_49')} className='TextHeader3 '> {item.sub_49}</p> :null }  
                          {item.sub_50 != ''? <p onClick={() => CbtOpenHandler(item.sub_50,'sub_50')} className='TextHeader3 '> {item.sub_50}</p> :null } 
                            
 
                          </div>
                          </Col>


                      


                        </Row>
                    </Card> 
                </div>
                ))
            :
                  <Card>
                      <Row>
                          <Col>
                                <p> No Data Fetch</p><br/>

                                <Button    onClick={() => navigate('./')} style={{  fontSize:14,marginBottom: 2, }}>Back to home </Button> 
                          </Col>
                      </Row>
                  </Card>
      }

                      {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?


                        <Modal  show={true}  style={{width:'100%'}} > 
    
                        <div   onClick={() => setMessageSwitch(false)}   >
                      <AiFillCloseCircle   style={{marginLeft:'50%',paddingTop:2}} size={25}  color={'black'} /> 
                        </div>
    
                        <div  style={{textAlign:'center'}} > 
                      <FaEnvelopeOpenText className="blink_me" color={'black'}size={25}   />
                        </div>
                        
     
           
                                    <div>
                                           
                                    <div  style={{fontSize: 14,color:'black',padding:5,textAlign:'center'}} >{message}</div> 
    
                                    </div>  
                            
                        
                  
                        </Modal>
                        :  
                        null
    
                        }   

        </div>
        );

  }


}






}



 