import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BodyColor,TopTabColor }  from '../../BaseUrl';
import {FaArrowLeft,FaHourglassHalf,FaCoins,FaGamepad,FaUsers,FaEnvelopeOpenText,FaRegMoneyBillAlt,FaUnlock,FaLock,FaLockOpen,FaRegTrashAlt,FaHistory} from "react-icons/fa";  
import { BsCheck2Circle } from "react-icons/bs";
import { AuthContext } from '../context'; 
import {AiFillCloseCircle} from "react-icons/ai";  
import '../../App.css'; 
import { BaseAction, imageURL } from '../../BaseUrl'; 
import Loading3 from '../loaders/Loading3'; 
import Loading4 from '../loaders/Loading4';
import Loading from '../loaders/Loading';
import ResultChecker from './CBT/ResultChecker';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row ,Modal} from 'react-bootstrap';



export default function  CheckExamResult() {
    const{  reloadUserData,signOut } = React.useContext(AuthContext);
    const navigate = useNavigate();    
    const[activateLoader, setActivateLoader]   = useState(false); 
    const[isLoading2, setIsLoading2]   = useState(false);  
    const[cbtSwitchPage, setCbtSwitchPage]   = useState(false); 
    const[loginData, setLoginData] = useState([]);  
    const[message, setMessage] = useState('');  
    const[studenResultData,  setStudenResultData] = useState([]);  
    const[schoolCode, setSchoolCode] = useState('');  
    const[studentID, setStudentID] = useState('');  
    const[studentClass, setStudentClass] = useState('');  
    const[studentName, setStudentName] = useState('');  
    const[studentPhoto, setStudentPhoto] = useState('');  
    const[studentTeacher, setStudentTeacher] = useState(''); 
    const[messageSwitch, setMessageSwitch] = useState(false) 
    const[resultType, setResultType] = useState('') 

 
 const search = useLocation().search;

 const LoginOptionUpdate =() => {
     
 

    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 

   
  };


  useLayoutEffect(() => {
 

    LoginOptionUpdate();
 
           
  },[]); 
  
  
  
    useLayoutEffect(() => {
      
  
      const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
      const student_teacher= new URLSearchParams(search).get('student_teacher'); 
      const school_code    = new URLSearchParams(search).get('school_code'); 
      const student_class  = new URLSearchParams(search).get('student_class'); 
      const student_name   = new URLSearchParams(search).get('student_name'); 
      const photo          = new URLSearchParams(search).get('photo'); 
      
      setStudentTeacher(student_teacher);
      setSchoolCode(school_code);
      setStudentID(online_stu_id);
      setStudentClass(student_class);
      setStudentName(student_name);
      setStudentPhoto(photo);
  
  
  
 
      
      },[]);
  


const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    setCbtSwitchPage(false)
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }
  
  
  const FetchAssessmentResult =() => {
    setStudenResultData('');
    setActivateLoader(true);
    setIsLoading2(true);

      
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"FetchAssessmentResult&student_ID="+studentID+"&school_code="+schoolCode,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  
                     
                    if(responseJson[0].question == 'null'){
                    
                       
                      setCbtSwitchPage(false);
                      setMessage('No Assessment Result Yet')
                      setMessageSwitch(true)
  
  
                     }else{
                      setStudenResultData(responseJson);                    
                      setResultType('ASSESSMENT RESULT');
                      setCbtSwitchPage(true)                   
                     }
                     


                    setActivateLoader(false);
                    setIsLoading2(false);


            })
            .catch((error) => { 
  
                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')
  
            });
   
     
    };


  const FetchMidTermTestResult =() => {
    setStudenResultData('');
    setActivateLoader(true);
    setIsLoading2(true);

      
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"FetchMidTermTestResult&student_ID="+studentID+"&school_code="+schoolCode,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  
                     
                    if(responseJson[0].question == 'null'){
                    
                       
                      setCbtSwitchPage(false);
                      setMessage('No Mid-Test Result Yet')
                      setMessageSwitch(true)
  
  
                     }else{
                      setStudenResultData(responseJson);                    
                      setResultType('MID-TERM TEST RESULT(30 MARKS)');
                      setCbtSwitchPage(true)                   
                     }
                     


                    setActivateLoader(false);
                    setIsLoading2(false);


            })
            .catch((error) => { 
  
                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')
  
            });
   
     
    };

  const FetchExamResult =() => {
   
    setActivateLoader(true);
    setIsLoading2(true);

     
    
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"FetchExamResult&student_ID="+studentID+"&school_code="+schoolCode,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  
                      


                    if(responseJson[0].question == 'null'){
                    
                       
                      setCbtSwitchPage(false);
                      setMessage('No Examination result Yet')
                      setMessageSwitch(true)
  
  
                     }else{
                      setStudenResultData(responseJson);                    
                      setResultType('EXAMINATION RESULT(70 MARKS)'); 
                      setCbtSwitchPage(true)                   
                     }

                      setActivateLoader(false);
                      setIsLoading2(false);

            })
            .catch((error) => { 
  
                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')
  
            });
   
     
    };

 const CbtOpenHandler = (val) => {
 
 

      if(val === 'test') {

        FetchMidTermTestResult();

      }else if(val === 'exam'){

        FetchExamResult();

      }else if(val === 'asessment'){

        FetchAssessmentResult();

      }


 

  }



if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Results"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{
 
  if(cbtSwitchPage)
  {
    return(
        
      <ResultChecker  
      ResultType={resultType} 
      StudenResultData={studenResultData} 
      school_code={schoolCode}   
      studenCode={studentID} 
      student_class={studentClass}    
      ReLoadHanduler={ReLoadHanduler}    
      />
    );

  }
  else
  {

    return (
        
        <div  style={{textAlign:"center",padding: 20,}} >

          <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code}}> 
          <Container>  
          <Row>
                <Col xs={5}>
                <img src={imageURL+loginData.school_code+'/'+studentPhoto} style={{marginTop:10,  }} className="imgLogo2"/>
                </Col>

                <Col xs={7} style={{textAlign:'left'}}> 
                <p  className="Bannerheader" style={{color:loginData.text_code,textTransform:'uppercase'}}> {studentName}</p>    
                <p   className="Bannerheader2" style={{marginTop:-15,  textTransform:'uppercase'}}> 
                    Student Teacher: {studentTeacher} <br/>
                    Student ID:      {studentID} <br/>
                    Student Class:   {studentClass}<br/>
                    School Code:     {schoolCode}
                    </p> 
                </Col>
            </Row>
          </Container>

          </div>

          <div style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}>
          <span onClick={() => navigate('/')}> <FaArrowLeft   size={25} style={{color:  'black',marginRight:20}}/> </span>
          SELECT RESULT
          </div>
 
                    <Card  style={{marginTop:10,paddingTop:50,paddingBottom:50}} > 
                      <Row>            
                          <Col  xs={12}>
                          
                                 <div  className='TextHeader3' onClick={() => CbtOpenHandler('asessment')} style={{  fontSize:18,marginBottom: 2,textAlign:'center' }}>Assessment Result </div> 
                        
                          </Col>

                           <Col style={{marginTop:20,}}> 
                           <div  className='TextHeader3'  onClick={() => CbtOpenHandler('test')} style={{  fontSize:18,marginBottom: 2,textAlign:'center' }}>Mid-Term Test Result</div> 
                          </Col>

                           <Col style={{marginTop:20,}}>  
                            <div  className='TextHeader3' onClick={() => CbtOpenHandler('exam')} style={{  fontSize:18,marginBottom: 2,textAlign:'center' }}>Examination Result </div> 
                          </Col>





                        </Row>
                    </Card>  



                      {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?


                        <Modal  show={true}  style={{width:'100%'}} > 
    
                        <div   onClick={() => setMessageSwitch(false)}   >
                      <AiFillCloseCircle   style={{marginLeft:'50%',paddingTop:2}} size={25}  color={'black'} /> 
                        </div>
    
                        <div  style={{textAlign:'center'}} > 
                      <FaEnvelopeOpenText className="blink_me" color={'black'}size={25}   />
                        </div>
                        
     
           
                                    <div>
                                           
                                    <div  style={{fontSize: 14,color:'black',padding:5,textAlign:'center'}} >{message}</div> 
    
                                    </div>  
                            
                        
                  
                        </Modal>
                        :  
                        null
    
                        }   

        </div>
        );

}





}






}



 