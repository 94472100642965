import React from 'react';
import { BodyColor,TopTabColor }  from '../../BaseUrl';
import { AiOutlineReload} from "react-icons/ai";  

export default function Loading3( props ) {
  return (
    <div  style={{flex: 1, }}>  

            
    <div className="header">     
        <AiOutlineReload  color={BodyColor} size={40} />
        <p style={{fontSize: 16,marginTop: 20,fontFamily: 'Poppins-Rgular',color: "#333333",}}>  Network  Error </p>
        <p style={{fontSize: 16,marginTop: 2,fontFamily: 'Poppins-Rgular',color: '#333333',}}>Check Data Connection </p>

        <div   onClick={() => props.ReLoadHanduler()}  style={{backgroundColor: BodyColor, padding: 10,borderRadius: 5,marginTop: 10}}  >
        <p style={{fontSize: 16,color: 'white',fontFamily: 'Poppins-Rgular',}}>RELOAD PAGE </p> 
        </div>

    </div>

  </div>

  );
}



 