import React from 'react';
 import { TopTabColor}  from '../../BaseUrl';

export default function Loading2(props) {
  return (
    <div style={{flex:1,backgroundColor:props.BgColor }}>
       
      
          
      
            <div className="header"> 
            <img src="images/loading.gif"   style={{ width: 50, height: 50 }} /> 
            <p  style={{ textAlign: 'center',color:'#fff',fontSize:15 }}> Please Wait ...</p>
            </div>  
          
    
    
  </div>

  );
}

 