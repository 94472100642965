
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios"; 
import { FaUserCheck,FaUserLock } from "react-icons/fa";
import { MdTipsAndUpdates } from "react-icons/md";
import { BsShieldLockFill } from "react-icons/bs";
import Modal from "../UI/Modal/Modal";  
import { BasePageAction } from '../../BaseUrl';
import md5               from 'md5-hash';
import { AuthContext } from '../../components/context';
import './Login.css';
 

function Login() { 
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();   


  const { signIn, bgLogo } = React.useContext(AuthContext);



  // function refreshPage() {
  //   setTimeout(()=>{
  //       window.location.reload(false);
  //        setShow(true);
  //   }, 5000);
     
  // }



  const showHandler =()=>{

    setShow(false);
    //refreshPage();
 }
 


  const handleSubmit = e =>  {
  e.preventDefault();

  setShow(true);
  
  if(password == '' || phone == '' 
  ){
    setMessage('All form fields are required');
  }
  else
  {

      const data = {   
        stuID: phone, 
        password: md5(password)
    
      };

     
       
        var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        
        fetch(BasePageAction+"StudentLogin",
        {
        method:'POST',
        header:headers,
        body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
         


              if(response[0].success == 'ok')
              {
                
                        var obj = {
         
                          admincode            : response[0].admincode,
                          marketer_code        : response[0].marketer_code,
                          fadmin_code          : response[0].fadmin_code,
                          school_code          : response[0].school_code,
                          school_name          : response[0].school_name,
                          school_photo         : response[0].school_photo,
                          school_logo          : response[0].school_logo,
                          school_email         : response[0].school_email,
                          school_password      : response[0].school_password,
                          school_phone         : response[0].school_phone,
                          school_address       : response[0].school_address,
                          school_motor         : response[0].school_motor,
                          school_bgcolor       : response[0].school_bgcolor,
                          text_code            : response[0].text_code,
                          school_week          : response[0].school_week,
                          last_pay_date        : response[0].last_pay_date,
                          bank_name            : response[0].bank_name,
                          account_name         : response[0].account_name,
                          account_number       : response[0].account_number,
                          school_status        : response[0].school_status,
                          schl_propritor_name  : response[0].schl_propritor_name,
                          schl_propritor_photo : response[0].schl_propritor_photo,
                          schl_propritor_msg   : response[0].schl_propritor_msg,
                          schl_head_name       : response[0].schl_head_name,
                          schl_head_photo      : response[0].schl_head_photo,
                          schl_head_msg        : response[0].schl_head_msg,
                          date_reg             : response[0].date_reg,
                          exam_score           : response[0].exam_score,
                          exam_time            : response[0].exam_time,
                          test_score           : response[0].test_score,
                          test_time            : response[0].test_time,
                          school_website       : response[0].school_website,
                                                              
                           //////////////////////////////////PARENT BLOCK /////////////////////////////

                          admin_code           : response[0].admin_code,
                          sch_code             : response[0].sch_code,
                          username             : response[0].username,
                          parent_code          : response[0].parent_code,
                          guidance_name        : response[0].guidance_name,
                          address              : response[0].address,
                          email                : response[0].email,  
                          ptoken               : response[0].ptoken,  
                          

                              //////////////////////////////////STUDENT BLOCK /////////////////////////////                                                    
                                          
                          admincode           : response[0].admincode,
                          photo               : response[0].photo,
                          schl_stu_no         : response[0].schl_stu_no,
                          online_stu_id       : response[0].online_stu_id,  
                          student_name        : response[0].student_name,
                          stu_gender          : response[0].stu_gender,
                          student_class       : response[0].student_class,
                          class_rep           : response[0].class_rep,
                          student_teacher     : response[0].student_teacher,
                          sub_status          : response[0].sub_status,
                          token               : response[0].token,
                          date                : response[0].date,     
                          portal_lock         : response[0].portal_lock,     


                            };
                      
                      
      
                                 
                                localStorage.setItem('reg_status', response[0].sub_status);  
                                localStorage.setItem('login_session',JSON.stringify(obj));
                                localStorage.setItem('school_bgcolor', response[0].school_bgcolor);
                                localStorage.setItem('text_code', response[0].text_code);
                                localStorage.setItem('authLogin', '1');
                                localStorage.setItem('online_stu_id', response[0].online_stu_id);
                                localStorage.setItem('token', response[0].token);
                                localStorage.setItem('schoolCode', response[0].school_code);
                                localStorage.setItem('parentCode', response[0].parent_code);
                                localStorage.setItem('school_logo', response[0].school_logo);
                                localStorage.setItem('school_name', response[0].school_name);
                                localStorage.setItem('school_address', response[0].school_address);
                                localStorage.setItem('school_email', response[0].school_email);
                                localStorage.setItem('school_motor', response[0].school_motor);
                                localStorage.setItem('school_phone', response[0].school_phone);
                
                  
                      
              
                      signIn();
              }
              else   
              {
                
                setMessage(response[0].feedback);
              }
            


       
      
      })
      .catch(error => {
        setMessage('Oops! network error, please check network data and try again');
      });

}
}
 
// <img src="images/favicon.png"   className="imgLogoL"/> <br />
 
    if(show){ 
      return (
        <div>
              {
                    
                message == ''?
                  <center>
                    <Modal show={show}  >    
                    <img src="images/loading.gif"  style={{height: '100px'}}   /> 
                    <br  /> <small style={{color: 'white'}}>Please Wait..</small>
                    </Modal> 
                  </center>
                  :
                  <div >
                      <center>
                        
                        
                        <Modal show={show}   clicked={()=>setShow(false)} > 
                          
                            <div   className="rackdropDiv" style={{color: 'white'}}  > 
                            <MdTipsAndUpdates className="blink_me" style={{fontSize: 60,color: '#0278c0'}} /><br/>
                              {message} 
                            </div>

                        </Modal>
                        

                      </center>
                  </div>
            
              }

      </div>

      )
    }
    else
    {
       
        return (
        <div className="App">
        <div className="App-header">

        <div className="ContainerL">  

        <div  className='homeHr'>
       
        <div className="TextHeadLogin" style={{marginTop:100,fontSize: 20,color: '#0278c0',fontWeight:'bold'}}> STUDENT <br /> PORTAL LOGIN</div> 
        </div>






        <form   onSubmit={handleSubmit}>



        <div className='form-inputs'>
        <label htmlFor='create-phone' className='form-label' style={{color: '#fff'}}> <FaUserLock style={{fontSize: 25,color: '#0278c0'}} />   Student ID </label>
        <input
        style={{color: 'black',backgroundColor:'#fff'}}
        id="stuID"
        type="text"
        name="stuID"
        placeholder='Student ID'
        className="form-input-login" 
        onChange={e=> setPhone(e.target.value)}
        />

        </div>


 


        <div className='form-inputs'>
        <label htmlFor='create-password' className='form-label' style={{color: '#fff',marginTop:30}}>  <BsShieldLockFill style={{fontSize: 20,color: '#0278c0'}} /> Student Password </label>
   

        <input
        style={{color: 'black',backgroundColor:'#fff'}}
        id="password"
        type="password"
        name="password"
        className="form-input-login"
        placeholder='Portal password'
        onChange={e=> setPassword(e.target.value)}
        />
        </div>




        <button type="submit"   className="btntab--active" style={{marginTop: '30px',width: '95%', marginLeft:-15 }}>
         LOGIN
        </button>

        <div style={{flexDirection: 'row',justifyContent: 'space-between',marginTop:3,marginBottom:8, fontSize: 14}}>
             
     

      </div>
        </form>


       
        </div>
        </div>


        </div>
        )


    }
    
  }
  //adelanwas088@gmail.com
  //ADElanwa012@
  //
export default Login