
import React, { useState, useEffect, useLayoutEffect}  from 'react';
import { FaUserCheck,FaUserLock } from "react-icons/fa";
import md5               from 'md5-hash';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
import '../../App.css';  



export default function ExamAccessCode(props) {
    const [message, setMessage] = useState(''); 
    const [tokenAccess, setTokenAccess] = useState(null);  

    useLayoutEffect(() => {
     
        const school_bgcolor = localStorage.getItem('school_bgcolor');
        const text_code      = localStorage.getItem('text_code');
     
    
      },[]);

      const handleSubmit = () =>  {
  
        // console.log(tokenAccess)
        // console.log(props.token)
        // console.log(md5(tokenAccess) )
        
        
        if(tokenAccess == ''  ){
          setMessage('All form fields are required');
        }
        else
        {
 
      
      
                    if(md5(tokenAccess) === props.token)
                    {
                                               
                        props.cbtSwitchPage(true);
                        props.accessCode(false);
                        
                    }
                    else   
                    {
                      props.cbtSwitchPage(true)
                      props.accessCode(true)
                      setMessage('Sorry!, wrong access code passed. Contact your tracher for correct subject access code');
                      
                    }
                  
      }
      }
       

 

  return (
    <div className="dashboardLoder Backdropdashboard"   style={{backgroundColor:props.BgColor,}}>
       
        <center>
 
            <div style={{textAlign:'center',marginTop:'15%'}}>
           
            <p  style={{ textAlign: 'center',color:'#fff',fontSize:15 }}> {props.MessgNote} </p>
            <p  style={{ textAlign: 'center',color:'#fff',fontSize:15 }}> {message} </p>
       
         
           <div style={{width:'70%',marginLeft:'15%'}}>
           

           <Col>
           </Col>

           <Col>
                 
                  <FaUserLock style={{fontSize: 50,color: '#fff'}} /> 

                 
                  <div className='form-inputs'>
                  <label className='form-label' style={{color: '#fff'}}>Enter Access Code </label>
                  <input
                  style={{color: '#fff'}}
                  id="phone"
                  type="password"
                  name="phone"
                  className="form-input-login"
                  placeholder='************'
                  onChange={e=> setTokenAccess(e.target.value)}
                  />
          
                  </div>
          
          
            

                  <Row>

                    <Col  >
                    <button onClick={handleSubmit}   className="btntab--active" style={{marginTop: '30px',width: '100%', }}>
                    Confirm Code
                    </button>
                    </Col>

                    <Col  >
                    <button onClick={()=> props.cbtSwitchPage(false)}   className="btntab--active" style={{marginTop: '30px',width: '100%' }}>
                    Cancel
                    </button>
                    </Col>

                  </Row>
          



          
                  <div style={{color:props.textCode,flexDirection: 'row',justifyContent: 'space-between',marginTop:15,marginBottom:8, fontSize: 11}}>
                      
                Note: Access code is highly required to take this {props.CBT}, kindly source subject access code to take this assessment from your sschool teacher. Thanks 
          
                </div>
                              
           </Col>
          
           <Col>
           </Col>
           
           
         
           </div>          

            

            </div>  

        </center>
    
  </div>

  );


}

 