import React, { useState, useEffect, useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'
import { MdTipsAndUpdates,MdVerified,MdCancelPresentation } from "react-icons/md";
// import { BsShieldFillCheck } from "react-icons/bs";
// import { GiMoneyStack } from "react-icons/gi";
// import { GiAlliedStar} from "react-icons/gi";    
//  import { MdOutlineGppGood, MdGppGood,MdGroups} from "react-icons/md";  
// import {  FaHourglassHalf,FaHourglassEnd,FaExternalLinkAlt,FaUsers,FaUserTimes } from "react-icons/fa";  
// import { AiOutlineReload} from "react-icons/ai"; 
import {GiPadlock,GiPadlockOpen,GiCheckMark} from "react-icons/gi";  
import Modal from "../UI/Modal/Modal";  
//  
 import { AuthContext } from '../../components/context'; 
import './Dashboard.css'; 
import '../../App.css'; 
import { BaseAction, imageURL } from '../../BaseUrl'; 
import Loading3 from '../loaders/Loading3'; 
import Loading4 from '../loaders/Loading4';
import Loading from '../loaders/Loading';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
 



 

const Dashboard = (props) => {
  const [message, setMessage] = useState('');   
  const navigate = useNavigate();    

  const {  reloadUserData,signOut } = React.useContext(AuthContext);
 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  //////////////////////////////////////////////////////////////
  const [nullData, setNullData] = useState(false);  
  //////////////////////////////////////////////////////////////
  const[loginData, setLoginData] = useState([]);  
  //////////////////////////////////////////////////////////////
  const [studentOnList, setStudentOnList] = useState([]);  
  const [textCode, setTextCode] = useState('');
  const [bgColor, setBgColor] = useState('');

  const LoginOptionUpdate =() => {
     
 

    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 

   
  };


  useLayoutEffect(() => {
 

    LoginOptionUpdate();
 
           
  },[]);
 
 

    useLayoutEffect(() => {


      const school_bgcolor = localStorage.getItem('school_bgcolor');
      const text_code      = localStorage.getItem('text_code');
      setBgColor(school_bgcolor);
      setTextCode(text_code);

     

 
             
    },[]); 

 
 
   
 useEffect(() => {
    
  FetchEnrolledStudents();   
  
  },[]);
        

  

  const FetchEnrolledStudents =() => {
 
  
    const sch_code       =  localStorage.getItem('schoolCode');
    const token          =  localStorage.getItem('token');
    const online_stu_id  =  localStorage.getItem('online_stu_id');
    
     
    setActivateLoader(true);
    setIsLoading2(true);


    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"SchoolNewsletter&token="+token+"&school_code="+sch_code+"&student_code="+online_stu_id,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((response) =>
              {  

                if(response[0].success == 'failed ')
                  {
                    setNullData(true);
                    setActivateLoader(false);
                    setIsLoading2(false);
                  
                  }else{
                    setNullData(false);
                    setStudentOnList(response);
                    setActivateLoader(false);
                    setIsLoading2(false);                  
                  }

            })
            .catch((error) => { 

                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')

            });
  }
 
 
 

  const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }
 
if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Student"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{

          return (
        
            <div  style={{textAlign:"center",padding: 20,}} >

          <div     style={{backgroundColor:bgColor,color:textCode,width:'100%',paddingTop:20,paddingBottom:10}}> 
            <Container>  
            <Row>
                  <Col xs={3}>
                  <img src={imageURL+"/"+props.schoolCode+"/"+props.schoolLogo} style={{marginTop:10,  }} className="imgLogo"/>
                  </Col>

                  <Col xs={9} style={{textAlign:'left'}}> 
                      <p  className="Bannerheader"style={{fontWeight: 'bold',  color:textCode,textTransform:'uppercase'}}> {props.schoolName}</p>    
                      <p  className="Bannerheader2"style={{color:textCode, marginTop:-15, }}> 
                      Address: {props.schoolAddress} <br/>
                      Phone:   {props.schoolPhone} <br/>
                      Email:   {props.schoolEmail}<br/>
                      Mottor:  {props.schoolMotor}
                      </p> 
                  </Col>
              </Row>
            </Container>

          </div>
          <div style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}>SCHOOL NEWSLETTER </div>

          {
            nullData?
                      <Card>
                          <Row>
                              <Col>
                                    <p> No Data Fetch</p><br/>

                                    <Button   onClick={() => FetchEnrolledStudents()} style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode}}>Reload School Newsleter   </Button> 
                              </Col>
                          </Row>
                      </Card>
                :

                studentOnList.map((item) => (
                  <Card  className="BoxShaddow"  key={item.id} style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                    <Row>  

                        <Col>
                          <div>
                              <p style={{fontWeight: 'bold',color:bgColor,marginBottom: 5,fontSize:20,textTransform:'uppercase',paddingTop:10}}>
                                {item.header}
                              </p>
                          </div>
                       </Col> 

                        <Col  xs={12}>
                          <div style={{marginTop:30}}> 
                          <img src={item.img_url} style={{marginBottom:20,width: 300}} /><br/>
                         </div> 
                        </Col>

                        <Col  xs={12} style={{display:'flex',flexDirection:'row',paddingLeft:'3%',fontSize:16}}>
                      
                        <p style={{ fontSize:15,color:'black',padding:5,textTransform:'capitalize',textAlign:'left',color:bgColor,fontSize:13}}>Posted By {item.uploader_name} |</p>         
                        <p style={{ fontSize:15,color:'black',padding:5,textTransform:'capitalize',textAlign:'left',color:bgColor,fontSize:13}}>{item.date}</p>                               
                       </Col>


                      <Col xs={12} style={{textAlign:'left',paddingLeft:30}}>
                          
                      <textarea 
                      style={{border:'none',width:'100%',padding:5,fontSize:16}}
                      value={item.body}
                      
                      rows={50}
                      autosize/>
                                              
                      </Col>
            



                      </Row>
                  </Card> 

              ))
          }

           

            </div>
            );
                
 

        } 
};

export default Dashboard; 
 

  