import React, { useState, useEffect } from 'react';  
import {imageURL, TopTabColor}  from '../../BaseUrl';

 
export default function SchoolLoading( props ) {
  const[schoolBgcolor,  setSchoolBgcolor] = useState(''); 
 
  useEffect(() => {
 

    
    setTimeout(() => {
 

      const data1 = localStorage.getItem('school_bgcolor');
   
      setSchoolBgcolor(data1); 
 

  });


 
}); 
 
      
 
  return (
    <div   style={{height:2048,width:'100%',flex: 1,backgroundColor:props.schoolBgcolor}}>
     
    
     

       <div className="loader_center4" >  
     
       
        <p  style={{color:'white', fontSize: 19,marginBottom: 40,letterSpacing: 3}}> Welcome To</p> 
        
        <img src={imageURL+props.school_code+'/'+props.schoolLogo} style={{marginTop:30,marginBottom:20,width: 100,height: 100,}} />


        <p  style={{fontWeight: 'bold', fontSize: 20,color:'white',fontFamily: 'Poppins-Bold'}}> {props.schoolName}</p>    
        <p  style={{color:'white', fontSize: 12,marginBottom: 60,letterSpacing: 3}}> {props.schoolName}</p>   
        <p  style={{color:'white', fontSize: 15,marginBottom: 50,letterSpacing: 3}}>Motor:  {props.schoolMotor}</p>  
        
        

        <p  style={{marginTop:20,color:'white', fontSize: 15,marginBottom: 150,letterSpacing: 3}}>Please Wait..</p>   
       




        <p style={{fontSize:12,TextAlign:'center',color:'#777777'}}> Developed by Hepzihub Nig Ltd</p>

     </div> 

    </div>
 
  );
}


 